import React from "react"
import styles from "./second-text.module.scss"
import classNames from "classnames"

export default () => {
  return (
    <div className={classNames("textWrapper", styles.textWrapper)}>
      <div className={classNames("textBox")}>
        <h2>
          <div>
            Just let the{" "}
            <span className={classNames("salmonHighlight")}>lobster</span> eat
            your problems.
          </div>
        </h2>
        <p>
          The lobster is a fascinating animal. Highly specialized, efficient and
          perfectly adapted to its ecosystem. It does not age biologically and
          can live up to 140 years. It is a delicacy, not cheap, but extremely
          tasty. This makes it the perfect symbol for our company. Its traits
          are featured in our professional experience, knowledge and
          specialization in complex software projects. And like the lobster, we
          are not cheap, but every bit(e) is worth the price.
        </p>
      </div>
    </div>
  )
}
