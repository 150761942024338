import React from "react"
import Layout from "../components/layout"
import Intro from "../components/intro/intro"
import Outro from "../components/outro/outro"
import FirstText from "../components/content-components/first-text"
import SecondText from "../components/content-components/second-text"
import YellowBox from "../components/content-components/yellow-box"
import GreenBox from "../components/content-components/green-box"
import RedBox from "../components/content-components/red-box"
import "bootstrap/dist/css/bootstrap-grid.css"
import Container from "react-bootstrap/Container"
import { TwoColumn } from "../components/two-column/two-column"
import SEO from "../components/seo"
import BlueBoxTitle from "../components/content-components/blue-box-title"
import { ThirdText } from "../components/content-components/third-text"
import BlueBox from "../components/content-components/blue-box"

export default () => (
  <div className="full-height background-image marble">
    <SEO showDescription={true} />
    <Layout>
      <Container fluid={true}>
        <Intro />
        <TwoColumn left={<YellowBox />} right={<FirstText />} />
        <TwoColumn left={<BlueBoxTitle />} right={<BlueBox />} />

        <TwoColumn left={<GreenBox />} right={<ThirdText />} />
        <TwoColumn left={<SecondText />} right={<RedBox />} />
      </Container>
      <Container fluid={true}>
        <Outro />
      </Container>
    </Layout>
  </div>
)
