import React, { Component } from "react"
import classNames from "classnames"
import styles from "./blue-box.module.scss"
import animationWorkflow from "../../assets/animation/lobster-workflow.json"
import Lottie from "react-lottie"
import playImg from "../../assets/img/play.png"
import pauseImg from "../../assets/img/pause.png"
import stopImg from "../../assets/img/stop.png"
import fbwImg from "../../assets/img/fbw.png"
import ffwImg from "../../assets/img/ffw.png"

class BlueBox extends Component {
  state = { isStopped: false, isPaused: false, direction: 1, speed: 1 }

  render() {
    const DefaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationWorkflow,
      rendererSettings: {
        //preserveAspectRatio: "xMidYMid slice",
      },
    }
    return (
      <div className={classNames(styles.blueBox)}>
        <Lottie
          options={DefaultOptions}
          speed={this.state.speed}
          direction={this.state.direction}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div className={classNames(styles.controls)}>
          <button
            onClick={() => {
              this.setState({
                isStopped: false,
                isPaused: false,
                speed: 400,
                direction: -1,
              })
              setTimeout(
                () =>
                  this.setState({
                    isStopped: false,
                    isPaused: false,
                    speed: 1,
                    direction: 1,
                  }),
                10
              )
            }}
          >
            <img src={fbwImg} alt="Fast-Backward-Button" />
          </button>

          <button onClick={() => this.setState({ isStopped: true })}>
            <img src={stopImg} alt="Stop-Button" />
          </button>
          <button
            onClick={() =>
              this.setState({
                isStopped: false,
                isPaused: false,
                speed: 1,
                direction: 1,
              })
            }
          >
            <img src={playImg} alt="Play-Button" />
          </button>
          <button
            onClick={() => this.setState({ isPaused: !this.state.isPaused })}
          >
            <img src={pauseImg} alt="Pause-Button" />
          </button>
          <button
            onClick={() => {
              this.setState({
                isStopped: false,
                isPaused: false,
                speed: 400,
                direction: 1,
              })
              setTimeout(
                () =>
                  this.setState({
                    isStopped: false,
                    isPaused: false,
                    speed: 1,
                    direction: 1,
                  }),
                10
              )
            }}
          >
            <img src={ffwImg} alt="Fast-Forward-Button" />
          </button>
        </div>
      </div>
    )
  }
}

export default BlueBox
