import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import logoStyles from "../styles/modules/logo.module.scss"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "assets/img/Lobster_logo_white_rotate.png" }
      ) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <Img
      className={logoStyles.lobster}
      fadeIn={false}
      fixed={data.placeholderImage.childImageSharp.fixed}
    />
  )
}
