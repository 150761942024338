import React from "react"
import styles from "./green-box.module.scss"
import classNames from "classnames"

export default () => {
  return (
    <a
      href={"https://maps.app.goo.gl/KG98d2HEzBgVmLLR6"}
      target={"_blank"}
      rel="noopener noreferrer"
    >
      <div className={classNames(styles.greenBox)}></div>
    </a>
  )
}
