import React from "react"
import { run } from "tripetto-runner-autoscroll"
import Services from "tripetto-services"

const { snapshot, attachments, onSubmit, onPause } = Services.init({
  token:
    "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibHY1cTRQSGtlYkhhSzZVYlc5Y0NNTE5MY25xbGxaUmpXNklMMW9YdU5HQT0iLCJkZWZpbml0aW9uIjoickhVTDgrelVtME9VTlAxU3A2bFdRSlExOVJ4aVpLbm9vbVEza0lSd2NVZz0iLCJ0eXBlIjoiY29sbGVjdCJ9.65pX5A4gsmFbgWkZYnhwLO97yIBgDOirrpTWtFCdt8M",
})

export class ThirdText extends React.Component {
  constructor(props) {
    super(props)
    this.contentRef = React.createRef()
  }

  componentDidMount() {
    run({
      element: this.contentRef.current /* Or supply your own element here */,
      definition: {
        preface: { title: "Let's discuss your projects over a cup of coffee." },
        epilogue: {
          title: "Thank you for your time - look out for our message.",
        },
        clusters: [
          {
            id:
              "2263064bafae98c8887e5160ce73f8833a3883521c1b8c02a2705210ed3ad87f",
            name: "Call me or text us",
            nodes: [
              {
                id:
                  "460bcf5051e2031fe67e96645024538cf85b81a01111d04d4f006892cf2e0db0",
                name:
                  "Do you want us to reach out to you or would you like to tell us a bit more about your projects?",
                nameVisible: true,
                slots: [
                  {
                    id:
                      "20ac24c5ab61ac76ac3c5fd276f5cb62bfe4527417a7dd95c798dd358e0dd153",
                    type: "string",
                    kind: "static",
                    reference: "choice",
                    label: "Choice",
                    required: true,
                    exportable: false,
                  },
                ],
                block: {
                  type: "tripetto-block-multiple-choice",
                  version: "2.1.5",
                  choices: [
                    {
                      id:
                        "233ebb275fee418122e9708ac34ba9abd8bfc0a42af1afa3b933dc69fb09ff6f",
                      name: "I have some more details about my projects.",
                    },
                    {
                      id:
                        "d2486c02975908006ca5b4e9680c378a08e0d9d4068f98a23469563241c604ef",
                      name: "Let's schedule a call.",
                    },
                    {
                      id:
                        "db0fba8d01184d50fc588a5adc086ad139312ccef9e13b0645df51ff19480bc2",
                      name: "I would like to work for your company.",
                    },
                  ],
                  required: true,
                  exportable: false,
                },
              },
            ],
            branches: [
              {
                id:
                  "9355b181119477b09e5333485d4a25301c8706cbbbc318df43d6e81ea9c114d7",
                name: "Details for you",
                clusters: [
                  {
                    id:
                      "73f5fd0129d07a531c05e89cd82449a2e3d2ba12b25024f12f50cc517d653e94",
                    name: "Project",
                    nodes: [
                      {
                        id:
                          "4a91237fc54174008b97a55d93280f5253dfd96e9ea3f85d0d2f0db655e14dde",
                        name:
                          "This is great, we love projects! How do we call you?",
                        nameVisible: true,
                        placeholder: "Linus Torvalds",
                        slots: [
                          {
                            id:
                              "aa4496df7cc00bfae13b36682b17311dd9b42e1ab8bbc72e2e5f0e44a0dd3500",
                            type: "text",
                            kind: "static",
                            reference: "value",
                            label: "Text",
                            required: true,
                            exportable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-text",
                          version: "3.0.3",
                          autoComplete: "name",
                        },
                      },
                      {
                        id:
                          "1983f3593e5955d9665d74d1e4efd0fc5eab36d0375f7b67417e275b28de11f4",
                        name: "What kind of projects are you planning?",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "4f3f24d9d95b8d470393712497de21a4f7ffd72f541b0a529b603ba6aeed03a2",
                            type: "string",
                            kind: "static",
                            reference: "choice",
                            label: "Choice",
                            required: true,
                            exportable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-multiple-choice",
                          version: "2.1.5",
                          choices: [
                            {
                              id:
                                "9b60baf5abbc5705d7a341e9a694a3dcd13f1ac71f3725b9f34107f6c6fa1747",
                              name: "I want do digitise a business process",
                            },
                            {
                              id:
                                "e982878d341ea635b45c6ab92f20da5f5c0fe498c5ef5b16f64cde42f4b669c2",
                              name: "I need a POC for my new product",
                            },
                            {
                              id:
                                "b3ed9a12eddffe65cee8c2c01b3edd2bbe4cc8dff78982af6be41b3bd8d94685",
                              name: "Help me out with a legacy web application",
                            },
                            {
                              id:
                                "d75f67c775017a4b14d010406fdc22493c596c7b85bc095bdb51d1b65d51c3fc",
                              name: "I am interested in hosting and support",
                            },
                            {
                              id:
                                "09b05ef35b363c665b043e63dc1f96ebb4101cb7b02081d8c21d8d5416908ed2",
                              name: "I have something else in mind",
                            },
                          ],
                          required: true,
                          exportable: true,
                        },
                      },
                      {
                        id:
                          "895793c16c5adfe0c72a1bf7ad19d47b97ef1462def5afd9f31f5e26be424328",
                        name: "Oh my! Somebody wants do do a projects with you",
                        description:
                          "Hello Andreas,\nHello Daniel,\n\nmake sure to call this person - remember: you love projects! \n\nThanks\nthe lobster website\n(please really do it)",
                        slots: [
                          {
                            id:
                              "d78d4ea58fc17761de8311a0d60cf38a2581f22ab03a55948891c44c15a9ad54",
                            type: "string",
                            kind: "static",
                            reference: "recipient",
                            label: "Recipient",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "26e44d37fd8b8748dbe639984c25058d7bb8a5a10be9db3324033ae2e0c2bee4",
                            type: "string",
                            kind: "static",
                            reference: "subject",
                            label: "Subject",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "8ef44a1b45fd7c74a876c6f6dda2e004097ac48c3964cda7b1b2ff6f94494c0f",
                            type: "text",
                            kind: "static",
                            reference: "message",
                            label: "Message",
                            exportable: true,
                            actionable: true,
                          },
                          {
                            id:
                              "aa4a8ad1f3a166bf62082c24c6925e7cd9f91ad4a54d821220351e2c1c0c73fd",
                            type: "boolean",
                            kind: "static",
                            reference: "data",
                            label: "Data",
                            pipeable: false,
                            exportable: false,
                            actionable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-mailer",
                          version: "2.1.2",
                          recipient: "fixed",
                          recipientFixed: "office@lobster.ly",
                          includeData: true,
                          recipientExportable: false,
                          subjectExportable: false,
                          messageExportable: true,
                        },
                      },
                    ],
                    branches: [
                      {
                        id:
                          "338eb2158f0fe5cc85d4757ab53edee625e12ad818656d5f7fd76e643006bc88",
                        name: "Something else",
                        clusters: [
                          {
                            id:
                              "169821958110e580519552fe7ac76ed4acd3b1bb8f088e0547d41530ecc56093",
                            name: "What else",
                            nodes: [
                              {
                                id:
                                  "64b5cf6036bf3f3202015ac8c151c87610e93fa9822a7dcfb91c6513314575bd",
                                name: "Hit us with your story then!",
                                nameVisible: true,
                                slots: [
                                  {
                                    id:
                                      "f0a2461ad3ca9ccd67a9010b6ebb37831bdb7f5ae28fcd2865e302b3511a6397",
                                    type: "text",
                                    kind: "static",
                                    reference: "value",
                                    label: "Multi-line text",
                                    required: true,
                                    exportable: true,
                                  },
                                ],
                                block: {
                                  type: "tripetto-block-textarea",
                                  version: "3.0.3",
                                },
                              },
                              {
                                id:
                                  "0150ee644feb95f59343d1c88e77f7f49211c9281eae59bb7544131cab158b64",
                                name:
                                  "Oh my! Somebody wants do do a projects with you",
                                description:
                                  "Hello Andreas,\nHello Daniel,\n\nmake sure to call this person - remember: you love projects! \n\nThanks\nthe lobster website\n(please really do it)",
                                slots: [
                                  {
                                    id:
                                      "15510cf55f76564a45bd6ab54a78623357bdd21e2c3f393302be26664693fc43",
                                    type: "string",
                                    kind: "static",
                                    reference: "recipient",
                                    label: "Recipient",
                                    exportable: false,
                                    actionable: true,
                                  },
                                  {
                                    id:
                                      "8f9043747e0122d958aaef8784a7d4b67dbbc8b4e26204902b4bb6c370e9663e",
                                    type: "string",
                                    kind: "static",
                                    reference: "subject",
                                    label: "Subject",
                                    exportable: false,
                                    actionable: true,
                                  },
                                  {
                                    id:
                                      "63e341d03876bbbb1e33b53f550dcede8be74a42b90bfaca6b37ea9116010ead",
                                    type: "text",
                                    kind: "static",
                                    reference: "message",
                                    label: "Message",
                                    exportable: true,
                                    actionable: true,
                                  },
                                  {
                                    id:
                                      "9d9490d08019a925d84896bb4a1d6efc484f8614f9a059dfbe89e383ff6eee73",
                                    type: "boolean",
                                    kind: "static",
                                    reference: "data",
                                    label: "Data",
                                    pipeable: false,
                                    exportable: false,
                                    actionable: true,
                                  },
                                ],
                                block: {
                                  type: "tripetto-block-mailer",
                                  version: "2.1.2",
                                  recipient: "fixed",
                                  recipientFixed: "office@lobster.ly",
                                  includeData: true,
                                  recipientExportable: false,
                                  subjectExportable: false,
                                  messageExportable: true,
                                },
                              },
                            ],
                          },
                        ],
                        conditions: [
                          {
                            id:
                              "01860549203a1445028060e529b929658be6bd16c070ac5bef8e61ebda4d2875",
                            block: {
                              choice:
                                "09b05ef35b363c665b043e63dc1f96ebb4101cb7b02081d8c21d8d5416908ed2",
                              type: "tripetto-block-multiple-choice",
                              version: "2.1.5",
                              node:
                                "1983f3593e5955d9665d74d1e4efd0fc5eab36d0375f7b67417e275b28de11f4",
                              slot:
                                "4f3f24d9d95b8d470393712497de21a4f7ffd72f541b0a529b603ba6aeed03a2",
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
                conditions: [
                  {
                    id:
                      "a616cfcac6ab5214dec3b8774edbceb6df9f4b7edd78e6865cd0374ec96c7632",
                    block: {
                      choice:
                        "233ebb275fee418122e9708ac34ba9abd8bfc0a42af1afa3b933dc69fb09ff6f",
                      type: "tripetto-block-multiple-choice",
                      version: "2.1.5",
                      node:
                        "460bcf5051e2031fe67e96645024538cf85b81a01111d04d4f006892cf2e0db0",
                      slot:
                        "20ac24c5ab61ac76ac3c5fd276f5cb62bfe4527417a7dd95c798dd358e0dd153",
                    },
                  },
                ],
              },
              {
                id:
                  "4b4dd28c4b62214f3bd982477b3747de1af07ebc4a6a2c7e03baba3331f27aa9",
                name: "Call me maybe",
                clusters: [
                  {
                    id:
                      "205920be115b25c382cbe8fe7a939e28ba022dcf419ab5876123fedcadd860fc",
                    name: "Phone",
                    nodes: [
                      {
                        id:
                          "47e93ce7b21a100b8e74b019acc1c60b038cf77ad1535d38c778afec292e6698",
                        name: "Sure, please let us know your phone number.",
                        nameVisible: true,
                        placeholder: "+43 664 1234000",
                        slots: [
                          {
                            id:
                              "6fb87c6cadec5a5e3a2482d45edc533870af5986122a933ee20c87bd3fdce677",
                            type: "string",
                            kind: "static",
                            reference: "phone-number",
                            label: "Phone number",
                            required: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-phone-number",
                          version: "1.0.16",
                        },
                      },
                      {
                        id:
                          "fa7c98b1c79d28ca7872d6f2d0c149c3ef19a123f0bc41a78a1bbec39fb8cb5c",
                        name:
                          "Let us practice your name's pronunciation in advance! Who are you?",
                        nameVisible: true,
                        placeholder: "Linus Torvalds",
                        slots: [
                          {
                            id:
                              "df2ccb7492eaeedbceb7055a0eb2e6a07a4fef48020df012cf9ea504b55f68d7",
                            type: "text",
                            kind: "static",
                            reference: "value",
                            label: "Text",
                            required: true,
                            exportable: true,
                            transformation: "none",
                          },
                        ],
                        block: {
                          type: "tripetto-block-text",
                          version: "3.0.3",
                          autoComplete: "name",
                        },
                      },
                      {
                        id:
                          "4445bba6e42506d6a024c77c311897fd7a59b2286f241c9d7816172cefbe23f6",
                        name: "Do you have a preferred time and date?",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "d7aa3ba175a43897eed870815b87c8fb290824defd2dacc58e5523b5fe937438",
                            type: "date",
                            kind: "static",
                            reference: "date",
                            label: "Date with time",
                            precision: "minutes",
                          },
                        ],
                        block: {
                          type: "tripetto-block-date",
                          version: "1.0.18",
                          time: true,
                        },
                      },
                      {
                        id:
                          "d94eb6b7e2b86949d379765428959afb5a1a89e1ad0beee30a561a46955a4a56",
                        name:
                          "What do you want to chat about, @df2ccb7492eaeedbceb7055a0eb2e6a07a4fef48020df012cf9ea504b55f68d7?",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "c7266b849d80bbf4f660a0936d087256c1fd1d9037205a11c295715d126a3f27",
                            type: "text",
                            kind: "static",
                            reference: "value",
                            label: "Text",
                            required: true,
                            exportable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-text",
                          version: "3.0.3",
                        },
                      },
                      {
                        id:
                          "eca2dbf49e24d7e714e4e2b7c6068fb1ef32d699089497f45855a9966acd770b",
                        name: "Oh my! Somebody wants a call from the lobster",
                        description:
                          "Hello Andreas,\nHello Daniel,\n\nmake sure to call this potential lead.\n\nThanks\nthe lobster website",
                        slots: [
                          {
                            id:
                              "89493203c71d4b090948082b0c2352274b3a0de4af7c44cd8d27948d7784725b",
                            type: "string",
                            kind: "static",
                            reference: "recipient",
                            label: "Recipient",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "13c1e99f84094fb57af4b7c129a2cba17710f87acbcf3f2938fcf489ff397c5e",
                            type: "string",
                            kind: "static",
                            reference: "subject",
                            label: "Subject",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "4883e24c11a2159229489a95f09d07c40d39147a03e896eadc813b2e43eaf75a",
                            type: "text",
                            kind: "static",
                            reference: "message",
                            label: "Message",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "5fa86e01c069e140b19025193e33b62c318af5e3eea0611647f115a911f35b9c",
                            type: "boolean",
                            kind: "static",
                            reference: "data",
                            label: "Data",
                            pipeable: false,
                            exportable: false,
                            actionable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-mailer",
                          version: "2.1.2",
                          recipient: "fixed",
                          recipientFixed: "office@lobster.ly",
                          includeData: true,
                          recipientExportable: false,
                          subjectExportable: false,
                          messageExportable: false,
                        },
                      },
                    ],
                  },
                ],
                conditions: [
                  {
                    id:
                      "0902bbbaf1f4ec8ca9cc40d927ff7381dc93b841cdff51d1e45a77b691894a7d",
                    block: {
                      choice:
                        "d2486c02975908006ca5b4e9680c378a08e0d9d4068f98a23469563241c604ef",
                      type: "tripetto-block-multiple-choice",
                      version: "2.1.5",
                      node:
                        "460bcf5051e2031fe67e96645024538cf85b81a01111d04d4f006892cf2e0db0",
                      slot:
                        "20ac24c5ab61ac76ac3c5fd276f5cb62bfe4527417a7dd95c798dd358e0dd153",
                    },
                  },
                ],
                jump: "abort",
                epilogue: {
                  title:
                    "Thank you for reaching out - we will be in touch with you shortly.",
                },
              },
              {
                id:
                  "55894b90ae79c2b709b7729b7ec8338ce55bd939788018dd51fbe9106d5dbfa4",
                name: "Work you I want for",
                clusters: [
                  {
                    id:
                      "b0a8dd2b64bb0a154f07845a50282bf6c22f7037e54732db172e0e8e64145cfd",
                    name: "This me",
                    nodes: [
                      {
                        id:
                          "1072efd67d0c4bc2d09848fb12b0845d3b09b21bf052f2a1d8e14adc8a04a989",
                        name: "Describe yourself in a few words.",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "571569605159ded9b3b811bc6173cbfe58e2ccf59c60fa0c2a492db7c843c5f1",
                            type: "text",
                            kind: "static",
                            reference: "value",
                            label: "Multi-line text",
                            required: true,
                            exportable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-textarea",
                          version: "3.0.3",
                        },
                      },
                      {
                        id:
                          "16d9cb9debb42cec9f997c136df8b640374a2d59e0c67c1be80694ad9d398c58",
                        name:
                          "Please upload your CV here. Kindly use the portable document format.",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "25a1bd5084eee822635b99e3962ae6c3864d87d6d15c95ddb805cf4aae1de26c",
                            type: "string",
                            kind: "static",
                            reference: "file",
                            label: "File upload",
                            required: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-file-upload",
                          version: "2.0.3",
                          extensions: ".pdf",
                        },
                      },
                      {
                        id:
                          "b257b0dcb30224abc0c69ec8f9f25a320d0ee1cb4d4df2470521fa71e919eafc",
                        name: "Let us know how we can contact you.",
                        nameVisible: true,
                        slots: [
                          {
                            id:
                              "77cc7247e77b89319d18f6ad223715a383bed687a058986b1497e641c8dd7c37",
                            type: "string",
                            kind: "static",
                            reference: "email",
                            label: "Email address",
                            required: true,
                            exportable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-email",
                          version: "3.1.0",
                        },
                      },
                      {
                        id:
                          "040e4c5b42c66d36a8bb1c59ab9e8a65600831d9eeb35251f3f00cc0b4b52c6b",
                        name: "Oh my! Somebody wants to be a lobster",
                        description:
                          "Hello Andreas,\nHello Daniel,\n\nthere is a new recruit.\nPotentially.\n\nCheers\nthe lobster website",
                        slots: [
                          {
                            id:
                              "f810e603b0e3bc2d8bede210b2106c0a28088cdb2a0d7cd6a1943af1e229d9b0",
                            type: "string",
                            kind: "static",
                            reference: "recipient",
                            label: "Recipient",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "8372b4a0541020622456e8d519d82488bc04cd0cb4c5e86226c01a60c6e98afa",
                            type: "string",
                            kind: "static",
                            reference: "subject",
                            label: "Subject",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "b2fae5e6746cb5921983fbb7a35513038abecf13e540eeb2bafe9201593520ba",
                            type: "text",
                            kind: "static",
                            reference: "message",
                            label: "Message",
                            exportable: false,
                            actionable: true,
                          },
                          {
                            id:
                              "74c454d359e610a0186fd839b3e1145ca847cd3dffc828e002f6ee65691ba56c",
                            type: "boolean",
                            kind: "static",
                            reference: "data",
                            label: "Data",
                            pipeable: false,
                            exportable: false,
                            actionable: true,
                          },
                        ],
                        block: {
                          type: "tripetto-block-mailer",
                          version: "2.1.2",
                          recipient: "fixed",
                          recipientFixed: "office@lobster.ly",
                          includeData: true,
                          recipientExportable: false,
                          subjectExportable: false,
                          messageExportable: false,
                        },
                      },
                    ],
                  },
                ],
                conditions: [
                  {
                    id:
                      "eaf8cb2537a11190562153a65cb9b8457874605a06a9c723ffbc0ff7dfca34ee",
                    block: {
                      choice:
                        "db0fba8d01184d50fc588a5adc086ad139312ccef9e13b0645df51ff19480bc2",
                      type: "tripetto-block-multiple-choice",
                      version: "2.1.5",
                      node:
                        "460bcf5051e2031fe67e96645024538cf85b81a01111d04d4f006892cf2e0db0",
                      slot:
                        "20ac24c5ab61ac76ac3c5fd276f5cb62bfe4527417a7dd95c798dd358e0dd153",
                    },
                  },
                ],
              },
            ],
          },
        ],
        builder: { name: "tripetto", version: "2.2.5" },
      },
      styles: {
        direction: "horizontal",
        verticalAlignment: "middle",
        hidePast: false,
        hideUpcoming: true,
        showNavigation: "auto",
        showProgressbar: false,
        showEnumerators: false,
        autoFocus: false,
        showSeparateSubmit: true,
        showPreviousButton: true,
        showScrollbar: false,
        noBranding: true,
        contract: { name: "tripetto-runner-autoscroll", version: "3.9.5" },
        font: {
          size: 16,
          family: "/ubuntu.css#Ubuntu",
        },
        background: { color: "#0f1a4b" },
        inputs: {
          borderSize: 1,
          roundness: 4,
          errorColor: "#ff4a6f",
          agreeColor: "white",
          declineColor: "white",
        },
        buttons: {
          mode: "fill",
          roundness: 8,
          finishColor: "#f6e24a",
          textColor: "#0f1a4b",
        },
      },
      attachments,
      onSubmit,
    })
  }

  render() {
    return (
      <div id={"TripettoContainerWrapper"}>
        <div id={"TripettoContainer"}>
          <div ref={this.contentRef}></div>
        </div>
      </div>
    )
  }
}
