import React, { Component } from "react"
import classNames from "classnames"
import twoColumnStyles from "./two-column.module.scss"

export class TwoColumn extends Component {
  render() {
    return (
      <div className={classNames("fullPage", "row")}>
        <div className={classNames(twoColumnStyles.left, "col-lg-6")}>
          {this.props.left}
        </div>
        <div className={classNames(twoColumnStyles.right, "col-lg-6")}>
          {this.props.right}
        </div>
      </div>
    )
  }
}
