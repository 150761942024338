import React from "react"
import styles from "./first-text.module.scss"
import classNames from "classnames"

export default () => {
  return (
    <div className={classNames("textWrapper", styles.textWrapper)}>
      <div className={classNames("textBox", styles.textBox)}>
        <h2>
          We are a delightful mix of innovative, motivated and highly skilled
          software engineers.
        </h2>
        <p>
          Outstanding university education, many years of experience in the
          development of complex software applications and high quality
          standards are the ingredients of our services. We love to work and we
          love to work together. Our solutions are crafted in accordance with
          the latest models in project management and software development. The
          more demanding a project is and the higher the requirements for
          confidentiality, integrity and availability are, the better it suits
          us.
        </p>
      </div>
    </div>
  )
}
