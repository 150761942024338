import React from "react"
import { Quote } from "../quote/quote"

export default () => {
  return (
    <div className="fullPage">
      <Quote />
    </div>
  )
}
