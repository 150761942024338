import React from "react"
import styles from "./red-box.module.scss"
import classNames from "classnames"
import lottie from "lottie-web"
import animationLobster from "../../assets/animation/lobster-eatproblems.json"

export default () => {
  let animationContainer2 = React.createRef()

  React.useEffect(() => {
    lottie.loadAnimation({
      container: animationContainer2.current,
      animationData: animationLobster,
      autoplay: true,
      path: animationLobster,
    })
  }, [])

  return (
    <div className={classNames(styles.redBox)}>
      <div
        className={classNames(styles.lobsterEat)}
        ref={animationContainer2}
      ></div>
    </div>
  )
}
