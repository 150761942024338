import React from "react"
import outroStyles from "./outro.module.scss"
import classNames from "classnames"
import exinImg from "../../assets/img/certifications/exin.png"
import itilImg from "../../assets/img/certifications/itil.png"

export default () => {
  return (
    <div className={classNames("quarterPage", "row", outroStyles.outro)}>
      <div className={classNames("col-lg-12", outroStyles.certifications)}>
        <img src={exinImg} alt="Exin-certification" />
        <img src={itilImg} alt="ITIL-certification" />
      </div>
    </div>
  )
}
