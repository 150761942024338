import React from "react"
import styles from "./blue-box-title.module.scss"
import classNames from "classnames"

export default () => {
  return (
    <div className={classNames("textWrapper", styles.textWrapper)}>
      <div className={classNames("textBox", styles.textBox)}>
        <h2>
          <div>There are better places to order food -</div>
          <span className={classNames("salmonHighlight")}>
            but no better place to finish your project.
          </span>
        </h2>
      </div>
    </div>
  )
}
