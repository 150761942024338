import React, { Component } from "react"
import quoteStyles from "./quote.module.scss"
import classNames from "classnames"
import Lobster from "../lobster"

export class Quote extends Component {
  render() {
    return (
      <div className={classNames(quoteStyles.quoteWrapper)}>
        <figcaption>
          <Lobster />
        </figcaption>
        <div className={classNames(quoteStyles.quote)}>
          <span className={classNames(quoteStyles.quoteSign)}>"</span>
          <h1 className={classNames(quoteStyles.mainText)}>
            We make realizing your software projects feel less like playing
            whack-a-mole and more like a fine dining experience.
          </h1>
        </div>
      </div>
    )
  }
}
